.react-select__control.react-select__control {
    background-color: #F5F8FA;
    border-color: #F5F8FA;
    color: #5E6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    font-size: 1.15rem;
    min-height: calc(1.5em + (1.65rem + 2px));
}
.react-select__single-value.react-select__single-value ,.react-select__multi-value__label.react-select__multi-value__label{
    color: #5E6278;
    font-size: 1.15rem;
}


